import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    
    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {
            type: 'dropDown',
            icon: 'i-Home-4',
            sub: [
                { icon: 'i-Home-4', name: 'Accueil', state: '/dashboard/accueil', type: 'link' },
                { icon: 'i-Add-User', name: 'Ancienneté', state: '/dashboard/anciennete', type: 'link' },
                { icon: 'i-Remove-User', name: 'Licenciement', state: '/dashboard/licenciement', type: 'link' },
                { icon: 'i-Checked-User', name: 'Départ à la retraite', state: '/dashboard/retraite', type: 'link' },
                { icon: 'i-Business-Mens', name: 'Heures sup', state: '/dashboard/heuresup', type: 'link' },
                { icon: 'i-Financial', name: 'IFC', state: '/dashboard/ifc', type: 'link' },
                { icon: 'i-Calendar-4', name: 'Congés', state: '/dashboard/conges', type: 'link' },
                { icon: 'i-Money1', name: 'Rappel differentiel', state: '/dashboard/rappdiff', type: 'link' },
            ]
        },
        {
            name: 'Documents',
            description: 'Code du travail, CCNI, et conventions collectives',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-File', name: 'Conventions', state: '/docs/conventions', type: 'link' },
                { icon: 'i-File', name: 'Autres documents', state: '/docs/autres', type: 'link' },
            ]
        },
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
