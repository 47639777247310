import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DocumentsService {
  token: any;
  constructor(private http: HttpClient) {
  }

  public getOtherDocs(): Observable<any> {
    return this.http.get<any>(environment.api + 'documents/');
  }

  public searchDoc(param): Observable<any> {
    return this.http.get<any>(environment.api + 'documents/?nom='+param);
  }

  public getConventionsFiles(): Observable<any> {
    return this.http.get<any>(environment.api + 'convention_collectives/');
  }

  public getConventionsAvenantFiles(id): Observable<any> {
    return this.http.get<any>(environment.api + 'convention_collectives/' + id + '/avenants/');
  }

  public searchConvention(param): Observable<any> {
    return this.http.get<any>(environment.api + 'convention_collectives/?nom='+param);
  }

}