import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class IndemnityService {

  constructor(private http: HttpClient) {
  }

  public getOlderConvention(): Observable<any> {
    return this.http.get<any>(environment.api + 'prime_anciennete/conventions/');
  }

  public getLicenciementConvention(): Observable<any> {
    return this.http.get<any>(environment.api + 'indemnite_licenciement/conventions/');
  }

  public getRetraiteConvention(): Observable<any> {
    return this.http.get<any>(environment.api + 'indemnite_depart_retraite/conventions/' );
  }

  public getHeurSupConvention(): Observable<any> {
    return this.http.get<any>(environment.api + 'heure_supplementaire/conventions/' );
  }

  public getCategoryConvention(id): Observable<any> {
    return this.http.get<any>(environment.api + 'convention_collectives/' + id + '/categories/');
  }

  public getAmontIndemnityOlder(data: any) {
    return this.http.post<any>(environment.api + 'prime_anciennete/', data);
  }

  public getAmontIndemnityLicenciement(data: any) {
    return this.http.post<any>(environment.api + 'indemnite_licenciement/', data);
  }

  public getAmontIndemnityRetraite(data: any) {
    return this.http.post<any>(environment.api + 'indemnite_depart_retraite/', data);
  }

  public getAmontIndemnityRetraiteOil(data: any) {
    return this.http.post<any>(environment.api + 'indemnite_depart_retraite/petrole/', data);
  }

  public getAmontSupHour(data: any) {
    return this.http.post<any>(environment.api + 'heure_supplementaire/', data);
  }

  public getAmontIFC(data: any) {
    return this.http.post<any>(environment.api + 'indemnite_fin_contrat/', data);
  }

  public getAmontRappelDiff(data: any) {
    return this.http.post<any>(environment.api + 'rappel_differentiel/', data);
  }

  public getConges(data: any) {
    return this.http.post<any>(environment.api + 'conges/', data);
  }
}
