import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { HttpClient} from '@angular/common/http';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  token: any;

  constructor(private store: LocalStoreService, private router: Router, private http: HttpClient) {
    this.token = store.getItem('token');
  }

  checkAuth() {
    return this.token = this.store.getItem("token");
  }

  getToken(): string {
    return this.store.getItem('token');
  }

  public signin(credentials) {
    return this.http.post<any>(environment.api + 'token/', credentials);
  }

  signout() {
    this.store.clear();
    this.router.navigateByUrl("/sessions/login");
  }

  public updatePassword(data) {
    return this.http.put<any>(environment.api + 'user/change_password/', data);
  }

  public getUserInfo() {
   return this.http.get<any>(environment.api + 'user/infos/');
  }

}
