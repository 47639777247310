<div class="main-header hidden-print">
    <div class="logo">
        <img src="./assets/images/logo2.png" alt="">
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div style="margin: auto"></div>
    <div class="header-part-right">
        <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
        <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
            <img src="./assets/images/faces/user.png" id="userDropdown" ngbDropdownToggle alt="">
            <div ngbDropdownMenu aria-labelledby="userDropdown">
                <div class="dropdown-header">
                    <i class="i-Lock-User mr-1"></i> {{user?.first_name}} {{user?.last_name}}<br>
                    <i class="i-Management mr-1"></i>{{user?.fonction_name}}
                </div>
                <button class="dropdown-item" (click)="open(modalBasic)">Modifier le mot de passe</button>
                <button class="dropdown-item" (click)="goToAdmin()">Administration</button>
                <button class="dropdown-item" (click)="signout()">Déconnexion</button>
            </div>
        </div>
    </div>
    <ng-template #modalBasic let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Modification de mot de passe</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="updateForm">
                <div class="col-md-12 form-group mb-3 text-danger" *ngIf="showMessage">
                    <p> Les mots de passe ne sont pas identiques.</p>
                </div>
                <div class="col-md-12 form-group mb-3">
                    <label for="password">Mot de passe actuel<b class="text-danger"> *</b></label>
                    <div class="input-group">
                        <input [type]="showText ? 'text' : 'password'" class="form-control"
                            placeholder="Entrez le mot de passe actuel" formControlName="oldPassword" />
                        <div class="input-group-text cursor" (click)="toggleShow()">
                            <img src="./assets/images/eye-open.png" width="18px" *ngIf="showText" />
                            <img src="./assets/images/eye-close.png" width="18px" *ngIf="!showText" />
                        </div>
                    </div>
                </div>
                <div class="col-md-12 form-group mb-3">
                    <label for="password">Nouveau mot de passe<b class="text-danger"> *</b></label>
                    <div class="input-group">
                        <input [type]="showText ? 'text' : 'password'" class="form-control"
                            placeholder="Entrez le nouveau mot de passe" formControlName="newPassword" />
                        <div class="input-group-text cursor" (click)="toggleShow()">
                            <img src="./assets/images/eye-open.png" width="18px" *ngIf="showText" />
                            <img src="./assets/images/eye-close.png" width="18px" *ngIf="!showText" />
                        </div>
                    </div>
                </div>
                <div class="col-md-12 form-group mb-3">
                    <label for="password">Confirmer le mot de passe<b class="text-danger"> *</b></label>
                    <div class="input-group">
                        <input [type]="showText ? 'text' : 'password'" class="form-control"
                            placeholder="Confirmer le mot de passe" formControlName="newPasswordConfirm" />
                        <div class="input-group-text cursor" (click)="toggleShow()">
                            <img src="./assets/images/eye-open.png" width="18px" *ngIf="showText" />
                            <img src="./assets/images/eye-close.png" width="18px" *ngIf="!showText" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" [ladda]="loadingBtn" class="btn btn-outline-dark btn-rounded"
                [disabled]="!updateForm.valid" (click)="save()">Enregistrer</button>
        </div>
    </ng-template>
</div>