import { Component, OnInit } from "@angular/core";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SearchService } from "src/app/shared/services/search.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { matchValidator } from "src/app/shared/services/confirm-password.validator";
import { LocalStoreService } from "src/app/shared/services/local-store.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header-sidebar-compact",
  templateUrl: "./header-sidebar-compact.component.html",
  styleUrls: ["./header-sidebar-compact.component.scss"]
})
export class HeaderSidebarCompactComponent implements OnInit {
  notifications: any[];
  updateForm: FormGroup;
  loadingBtn: boolean;
  showText: boolean;
  showMessage: boolean;
  message: any;
  user: any;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private userService: AuthService,
    private localeStorage: LocalStoreService,
    private toastr: ToastrService
  ) {
  }

  toggleShow() {
    this.showText = !this.showText;
  }

  ngOnInit() {
    this.updateForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordConfirm: ['', Validators.required]
    });
    this.user = this.localeStorage.getItem('user');

  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
  }

  signout() {
    this.auth.signout();
  }

  goToAdmin() {
    window.open(environment.bo_url, '_blank');
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then((result) => {
      }, (reason) => {
        console.log('Err!', reason);
      });
  }

  save() {
    let password1 = this.updateForm.get('oldPassword').value;
    let password2 = this.updateForm.get('newPassword').value;
    let password3 = this.updateForm.get('newPasswordConfirm').value;
    if (password2 != password3) {
      this.showMessage = true;
      return
    }
    let data = {
      'old_password': password1,
      'password': password2,
      'password2': password3
    }
    this.loadingBtn = true;
    this.userService.updatePassword(data).subscribe(response => {
      this.toastr.success('Mot de passe modifié avec succès!', '', { timeOut: 300000 });
      this.loadingBtn = false;
      this.modalService.dismissAll()
    },
      (error) => {
        this.loadingBtn = false;
        if (error.status == 401) {
          this.localeStorage.clear();
          this.router.navigateByUrl('/sessions/login');
        }
      }
    );
  }

}
