<div class="app-footer hidden-print">

	<div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
		<div><a routerLink="">Mentions légales</a></div>

		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<div>
				<p class="m-0">&copy; 2023</p>
				<p class="m-0">Tous droits réservés</p>
			</div>
		</div>
	</div>
</div>