import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UtilsService } from './shared/services/utils.service';
import { IndemnityService } from './shared/services/indemnity.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from './shared/services/dateparser.service';
import { DocumentsService } from './shared/services/documents.service';
import { JwtInterceptor } from './shared/services/interceptor';
import { OnlyNumberDirective } from './shared/directives/only-number.directive';

@NgModule({
  declarations: [
    AppComponent,
    OnlyNumberDirective
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [
    UtilsService,
    IndemnityService,
    DocumentsService,
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'XOF' },
    { provide: NgbDateParserFormatter,useClass: NgbDateFRParserFormatter },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
